import React, { useContext, useState } from 'react';
import { motion as m } from 'framer-motion';
import { Alert } from 'react-bootstrap';
import { Context } from '../../../lib/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const ValidateEmail = () => {
    const { API_LINK, email, usersID } = useContext(Context)
    const [codeGenerated, setCodeGenerated] = useState(false);

    const generateNewCode = async () => {
        const response = await fetch(API_LINK + '/generate-new-code', {
            method: 'POST',
            body: JSON.stringify({ userID: usersID, email }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                // Do stuff
                // setEmailValidated(data.emailValidated);
                setCodeGenerated(true);
                setTimeout(() => {
                    setCodeGenerated(false);
                }, 60000);
                console.log('Code generated!');
            });
        }
    }

    return (
        <div>
            <h1 style={{
                color: '#26ffe2', textAlign: 'center', fontFamily: 'Neon', marginTop: '10px', marginBottom: '20px',
                fontSize: '3em'
            }}>
                <span className="glow-text">You're not a bot!</span> <span style={{ fontSize: '0.7em' }}>🤖</span>
            </h1>
            <p>
                Well, we're pretty sure you're not, but you've not validated your email yet.
            </p>
            <p>
                When you signed up, we sent an email to <span style={{ color: '#26ffe2' }}>{email}</span>{', '}
                which contains a validation link. Click that link to confirm your address, and you'll be good to go.

            </p>
            <p>
                If you can't find the email, it's cool — we've got your six. Simply click the link below to
                generate a new one (making sure you've checked your spam folder).
            </p>
            <button className="green-glow-button-large" style={{ marginTop: '10px', marginBottom: '20px' }} id="submit"
                onClick={generateNewCode} disabled={codeGenerated}>
                <span id="button-text">
                    Generate New Link <FontAwesomeIcon icon={faPaperPlane} style={{ marginLeft: '10px' }} />
                </span>
            </button>
            {codeGenerated &&
                <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: 'easeOut' }}>
                    <Alert variant="success" style={{ textAlign: 'center' }}>
                        A new link has been sent to your email address.
                    </Alert>
                </m.div>
            }
            <p><i>NOTE: Make sure you give around 5 mins for the link to come through before trying again.</i></p>
        </div>
    )
}

export default ValidateEmail