import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { motion as m } from 'framer-motion';
import InvisibleInput from '../../Pages/PageElements/InvisibleInput';

const AddAgeGroups = (props) => {
    const { ageGroups, addNewAgeGroup, removeAgeGroup, setAgeGroups } = props;

    // eslint-disable-next-line
    const handleChangeValue = (event) => {
        let type = event.target.name.split('~')[0];
        let index = event.target.name.split('~')[1];
        let value = event.target.value;

        // Set the value to zero if it's empty
        if (value === '') {
            value = 0;
        } else {
            value = parseInt(value, 10);
            if (isNaN(value)) {
                value = 0; // Default to zero if parsing fails
            }
        }

        let ageGroupsClone = [...ageGroups]
        if (type === 'low_age') {

            ageGroupsClone[index].low_age = value;
        } else {
            ageGroupsClone[index].high_age = value;
        }

        setAgeGroups(ageGroupsClone);

    }

    return (
        <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: 'easeOut' }}>
            <p>Please add all the age groups for the competition.</p>
            {ageGroups?.length > 0 &&
                ageGroups.map((ageGroup, index) => (
                    <Row key={'add-age-group-' + index} style={{ marginBottom: '5px' }}>
                        <Col xs={4} />
                        <Col xs={1} style={{ textAlign: 'right' }}>
                            <InvisibleInput
                                value={ageGroup.low_age || 0}
                                onInput={handleChangeValue}
                                onBlur={() => { }} // Use handleBlur here
                                name={'low_age~' + index}
                                type={"text"}
                                weight={'normal'}
                                disabled={false}
                            />
                        </Col>
                        <Col xs={1} style={{ textAlign: 'center' }}>
                            <p>to</p>
                        </Col>
                        <Col xs={2}>
                            <InvisibleInput
                                value={ageGroup.high_age || 0}
                                onInput={handleChangeValue}
                                onBlur={() => { }} // Use handleBlur here
                                name={'high_age~' + index}
                                type={"text"}
                                weight={'normal'}
                                disabled={false}
                            />
                        </Col>
                        <Col xs={1} style={{ textAlign: 'right' }}>
                            <FontAwesomeIcon onClick={() => { removeAgeGroup(index) }} style={{ cursor: 'pointer' }} icon={faTrash} />
                        </Col>
                        <Col xs={3} />
                    </Row>
                ))
            }
            <button className="green-glow-button" style={{ width: '100%', marginTop: '30px' }} onClick={addNewAgeGroup}>
                <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faPlus} /> Add Age Group
            </button>
        </m.div>
    )
}

export default AddAgeGroups