import React from 'react';
import { motion as m } from 'framer-motion';
import Container from 'react-bootstrap/esm/Container';

const Page404 = () => {
    return (
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: 'easeOut' }}
            className="gradient-bg"

        >
            <Container style={{
                minHeight: '100vh',
                paddingTop: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center', // This will center the content vertically and horizontally
            }}>
                <h1 className="glow-text" style={{
                    marginBottom: '270px', textAlign: 'center', fontSize: '3em', fontFamily: 'Neon',
                    color: '#26ffe2'
                }}>
                    <span>
                        Woah there TRON. You've ventured out into the expanse.
                    </span><br />
                    <small>The page you're looking for doesn't seem to exist.</small><br />
                    <small><a href="http://localhost:3000" style={{ color: '#26ffe2' }}>Return to mission control</a></small>
                </h1>
            </Container>
        </m.div>
    )
}

export default Page404