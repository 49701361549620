import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const UpdateVirtualEventScoresModal = () => {
    const { API_LINK, showUpdateVirtualScoresModal, setShowUpdateVirtualScoresModal, usersID,
        updateVirtualScoresEventID, setUpdateVirtualScoresEventID, events, setEvents
    } = useContext(Context)
    const [exercises, setExercises] = useState(new Map());
    const [exerciseScores, setExerciseScores] = useState(new Map());
    const competitorID = usersID;
    const eventID = updateVirtualScoresEventID;
    const isLiveEvent = false;

    useEffect(() => {
        if (events) {
            for (let i = 0; i < events.length; i++) {
                if (events[i]._id === updateVirtualScoresEventID) {
                    for (let j = 0; j < events[i].virtual_competitors.length; j++) {
                        if (events[i].virtual_competitors[j].userID === usersID) {
                            setExerciseScores({ ...events[i].virtual_competitors[j].exercise_scores })
                        }
                    }
                    let eventExercises = []
                    for (let j = 0; j < events[i].exercises.length; j++) {
                        eventExercises.push(events[i].exercises[j])
                    }
                    setExercises([...eventExercises]);
                }
            }
        }
    }, [updateVirtualScoresEventID, events, usersID]);

    const handleSetScores = (event) => {
        const slug = event.target.name;
        let rawScore = event.target.value.replace(/:/g, ""); // Get raw input as string
        rawScore = rawScore.replace(/^0+/, "");

        if (/^\d*$/.test(rawScore)) { // Only allow numeric input
            const newScores = { ...exerciseScores };
            newScores[slug] = rawScore; // Store raw input as a string
            setExerciseScores(newScores);
        }
    };

    const handleClose = () => {
        setUpdateVirtualScoresEventID('');
        setShowUpdateVirtualScoresModal(false)
    };

    const updateCompetitorScores = async () => {
        const scores = { ...exerciseScores }
        const response = await fetch(API_LINK + '/update-competitor-score', {
            method: 'POST',
            body: JSON.stringify({
                competitorID, eventID, exercise_scores: scores, isLiveEvent
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                setEvents(data.events);
                handleClose();
            });
        }
    }

    const formatTime = (input) => {
        // Ensure input is a string, pad to 6 characters (if it's shorter)
        const paddedInput = String(input).padStart(6, '0');

        // Extract hours, minutes, seconds
        const hours = parseInt(paddedInput.slice(0, 2), 10);
        const minutes = parseInt(paddedInput.slice(2, 4), 10);
        const seconds = parseInt(paddedInput.slice(4, 6), 10);

        // Return the formatted time in HH:MM:SS
        return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    return (
        <>
            <Modal show={showUpdateVirtualScoresModal} onHide={handleClose} className="modal-dark">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className="glow-text" style={{
                            fontFamily: 'Neon', color: '#26ffe2', fontSize: '2em', lineHeight: '0.6em'
                        }}>
                            Competitor Scores
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Please keep us updated on your progress. Put in your scores below.
                    </p>
                    {exercises?.length > 0 &&
                        exercises.map((exercise, index) => {
                            let slug = exercise.name.toLowerCase().replace(/\s+/g, '_');
                            let exerciseType = exercise.type;
                            if (exercise.type !== 'Time') {
                                return (
                                    <Row
                                        key={'scores-for-' + slug + updateVirtualScoresEventID}
                                        style={{ marginBottom: '20px' }}
                                    >
                                        <Col style={{ textAlign: 'right', paddingTop: '7px' }} xs={8}>
                                            <h6>{exercise.name} ({
                                                exerciseType === "Weights" ? exercise.reps : exercise.distance
                                            } {
                                                    exerciseType === "Weights" ? "reps" : "km"
                                                })</h6>
                                        </Col>
                                        <Col xs={4}>
                                            {/* Input for raw score */}
                                            <Form.Control
                                                type="text"
                                                name={slug}
                                                value={(exerciseType === "Weights" ? exerciseScores[slug] : formatTime(exerciseScores[slug]))} // Show raw value
                                                onChange={handleSetScores}
                                                placeholder={
                                                    exercise.type === 'Weights'
                                                        ? 'kg'
                                                        : exercise.type === 'CV' && 'mins:secs'
                                                }
                                            />
                                        </Col>
                                    </Row>
                                );
                            } else {
                                return null;
                            }
                        })}

                    <p><i>NOTE: Please be aware that the value for weights is in 'kg' and value for time is in 'hh:mm:ss' i.e. 1 hr 21 mins and 34 secs is 01:21:34.</i></p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="white-glow-button float-left" style={{ float: 'left' }} onClick={handleClose}>
                        Close
                    </button>
                    <button className="green-glow-button" style={{ float: 'left' }} onClick={updateCompetitorScores}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UpdateVirtualEventScoresModal