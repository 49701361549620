import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import '../../../lib/css/Dropdown.css';

const AddSingleCompetitor = (props) => {
    const {
        handleAddData, age, email, firstname, lastname, gender, handleSelectGender, tagNumber
    } = props;

    return (
        <>
            <p>Please add a single competitor here by entering in their email below.</p>
            <Row>
                <Col xs={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control onChange={handleAddData} onBlur={handleAddData} value={tagNumber}
                            type="text" name="tag_number" placeholder="Tag Number" />
                    </Form.Group>
                </Col>
                <Col xs={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control onChange={handleAddData} onBlur={handleAddData} value={age}
                            type="number" name="age" placeholder="Age" />
                    </Form.Group>
                </Col>
                <Col xs={4}>
                    <DropdownButton
                        title={gender}
                        onSelect={handleSelectGender}
                        variant="info"
                        id="custom-dropdown"
                    >
                        <Dropdown.Item eventKey="Male">Male</Dropdown.Item>
                        <Dropdown.Item eventKey="Female">Female</Dropdown.Item>
                    </DropdownButton>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control onChange={handleAddData} onBlur={handleAddData} value={firstname}
                            type="text" name="firstname" placeholder="Firstname" />
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control onChange={handleAddData} onBlur={handleAddData} value={lastname}
                            type="text" name="lastname" placeholder="Lastname" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control onChange={handleAddData} onBlur={handleAddData} value={email}
                            type="email" name="email" placeholder="name@example.com" />
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}

export default AddSingleCompetitor