import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
import '../../lib/css/DarkModal.css';
import MapCompetitor from '../Pages/PageElements/EventElements/MapCompetitor';
import Spinner from '../Pages/PageElements/Spinner';
import { motion as m } from 'framer-motion';
import Form from 'react-bootstrap/Form';
import '../../lib/css/App.css';
import '../../lib/css/LeagueTable.css';
import logo from '../../lib/Images/logo.png';

const EditCompetitorsModal = () => {
    const {
        API_LINK, showMapCompetitorsModal, setShowMapCompetitorsModal, mapCompetitorsEventID, setMapCompetitorsEventID, events,
        competitors, setCompetitors, setEditUserWeightsEventID, mapCompetitorsEventType, setMapCompetitorsEventType, isLiveEvent
    } = useContext(Context)
    const [event, setEvent] = useState({})
    const [startDate, setStartDate] = useState('');
    const [filterString, setFilterString] = useState('');

    const handleClose = () => {
        setShowMapCompetitorsModal(false);
        setTimeout(() => {
            setMapCompetitorsEventID('');
            setCompetitors([]);
            setStartDate('');
            setEvent({});
            setEditUserWeightsEventID('');
            setMapCompetitorsEventType('');
            setFilterString('');
        }, "200");
    }

    useEffect(() => {
        for (let i = 0; i < events?.length; i++) {
            if (events[i]._id === mapCompetitorsEventID) {
                let date = ''
                if (mapCompetitorsEventType === 'Live') {
                    console.log(events[i].start_time_live.split('T')[0])
                    date = events[i].start_time_live.split('T')[0];
                } else if (mapCompetitorsEventType === 'Virtual') {
                    date = events[i].start_time_virtual.split('T')[0];
                }
                let year = date.split('-')[0];
                let month = date.split('-')[1];
                let day = date.split('-')[2];
                setStartDate(day + "-" + month + "-" + year);
                setEvent(events[i]);
            }
        }
        const getCompetitors = async () => {
            const response = await fetch(API_LINK + '/get-competitors-for-event', {
                method: 'POST',
                body: JSON.stringify({ competitors: isLiveEvent ? event.competitors : event.virtual_competitors }),
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            })
            if (response.ok) {
                response.json().then(async data => {
                    if (data.error) {
                        console.log('There was an error: ' + data.error);
                    } else {
                        setCompetitors(data.competitors);
                    }
                });
            }
        }
        getCompetitors();
    }, [events, mapCompetitorsEventID, API_LINK, event.competitors, setCompetitors, mapCompetitorsEventType, isLiveEvent,
        event.virtual_competitors
    ]);

    const handleFilterCompetitors = (event) => {
        const value = event.target.value;
        setFilterString(value);
    }

    const displayedCompetitors = filterString?.length > 0
        ? competitors.filter((competitor) => {
            return (
                competitor.firstname.toLowerCase().includes(filterString.toLowerCase()) ||
                competitor.lastname.toLowerCase().includes(filterString.toLowerCase()) ||
                competitor.tag_number.toLowerCase().includes(filterString.toLowerCase()) ||
                competitor.email.toLowerCase().includes(filterString.toLowerCase())
            );
        })
        : competitors;

    return (
        <Modal size="xl" show={showMapCompetitorsModal} onHide={handleClose} className="modal-dark">
            <Modal.Header closeButton>
                <Modal.Title>
                    <img src={logo} style={{ height: '30px', width: 'auto' }} alt="Description" />
                    Competitors for Event Starting on {startDate && startDate} {event && '(' + event.address + ')'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {competitors?.length > 0 ?
                    <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: 'easeOut' }}>
                        <Form.Control
                            type="text"
                            id="search-competitors"
                            aria-describedby="search-help-block"
                            placeholder="Search for competitor"
                            onInput={handleFilterCompetitors}
                            style={{ marginTop: '15px' }}
                        />
                        <Form.Text id="search-help-block" muted >
                            <span style={{ color: 'white' }}>Search for firstname, lastname, email, or ID number.</span>
                        </Form.Text>
                        <table style={{
                            width: '100%', marginTop: '30px', borderSpacing: '0 15px', padding: '20px',
                            borderCollapse: 'collapse'
                        }}>
                            <thead>
                                <tr>
                                    <th style={{ padding: '10px' }}>First name</th>
                                    <th style={{ padding: '10px' }}>Last name</th>
                                    <th style={{ padding: '10px' }}>Email</th>
                                    <th style={{ padding: '10px' }}>Age</th>
                                    <th style={{ padding: '10px' }}>Gender</th>
                                    <th style={{ padding: '10px' }}>Tag number</th>
                                    <th style={{ padding: '10px' }}>Weights</th>
                                    <th style={{ padding: '10px' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedCompetitors.map((competitor, index) => (
                                    <MapCompetitor key={competitor._id + index}
                                        setCompetitors={setCompetitors} user={competitor} event={event} eventID={event._id}
                                        filterString={filterString}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </m.div>
                    :
                    <Spinner />
                }
            </Modal.Body>
            <Modal.Footer>
                <button className="grey-glow-button" onClick={handleClose}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditCompetitorsModal