import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Context } from '../../../../lib/Context';
import InvisibleInput from '../InvisibleInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import '../../../../lib/css/MapAdjudicator.css';

const MapCompetitor = (props) => {
    const { user, eventID, setCompetitors } = props;
    const {
        API_LINK, events, setEvents, setShowEditCompetitorWeightsModal, setEditUserWeightsUser, setEditUserWeightsEventID,
        isLiveEvent
    } = useContext(Context);
    const [gender, setGender] = useState(user.gender || 'Select');
    const [firstname, setFirstname] = useState(user.firstname || '');
    const [lastname, setLastname] = useState(user.lastname || '');
    const [email, setEmail] = useState(user.email || '');
    const [age, setAge] = useState(0);
    const [tagNumber, setTagNumber] = useState(user.tag_number || '');
    const [deletingCompetitor, setDeletingCompetitor] = useState(false);
    const [updatingUser, setUpdatingUser] = useState(false); // New state for spinner
    const userID = user._id;

    useEffect(() => {
        const event = events.find(event => event._id === eventID);
        let competitor = {}
        if (isLiveEvent) {
            competitor = event?.competitors.find(comp => comp.userID === userID);
        } else {
            competitor = event?.virtual_competitors.find(comp => comp.userID === userID);
        }

        if (competitor) {
            setAge(parseInt(competitor.age));
        }
    }, [events, userID, eventID, isLiveEvent]);

    const formatTagNumber = (value) => {
        return value.startsWith('#') ? value : `#${value}`;
    };

    const stripHashFromTagNumber = (value) => {
        return value.replace(/^#/, '');
    };

    const handleChangeData = async (event) => {
        let type = event.target.name;
        let value = event.target.value;

        if (type === 'id_number') {
            value = stripHashFromTagNumber(value);
        }

        switch (type) {
            case 'email':
                setEmail(value);
                break;
            case 'firstname':
                setFirstname(value);
                break;
            case 'lastname':
                setLastname(value);
                break;
            case 'age':
                setAge(value);
                break;
            case 'id_number':
                setTagNumber(value);
                break;
            default:
                break;
        }
    };

    const updateUser = async (props) => {
        let { updatedUser } = props;
        if (!updatedUser) {
            updatedUser = {
                userID,
                email,
                firstname,
                lastname,
                gender,
                eventID,
                tag_number: stripHashFromTagNumber(tagNumber),
                age
            };
        }

        setUpdatingUser(true);  // Start spinner
        const response = await fetch(API_LINK + '/update-user', {
            method: 'POST',
            body: JSON.stringify({ updatedUser, isLiveEvent, eventID }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        });
        if (response.ok) {
            const data = await response.json();
            if (data.error) {
                console.log('There was an error: ' + data.error);
            } else {
                setCompetitors(data.competitors);
                setEvents(data.events);
            }
        }
        setUpdatingUser(false);  // Stop spinner
    };

    const handleSelectGender = async (eventGender) => {
        setGender(eventGender);
        let updatedUser = {
            userID,
            email,
            firstname,
            lastname,
            gender: eventGender,
            eventID,
            tag_number: stripHashFromTagNumber(tagNumber),
            age
        };
        updateUser({ updatedUser });
    };

    const handleShowCompetitorInfo = useCallback(() => {
        setEditUserWeightsEventID(eventID);
        setShowEditCompetitorWeightsModal(true);
        setEditUserWeightsUser(user);
    }, [eventID, user, setEditUserWeightsEventID, setEditUserWeightsUser, setShowEditCompetitorWeightsModal]);

    const removeCompetitor = useCallback(async () => {
        const userConfirmed = window.confirm('Do you want to proceed?');
        if (userConfirmed) {
            setDeletingCompetitor(true);
            const response = await fetch(API_LINK + '/delete-competitor-from-event', {
                method: 'POST',
                body: JSON.stringify({ eventID, userID }),
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });
            const data = await response.json();
            if (response.ok) {
                setEvents(data.events);
            }
            setDeletingCompetitor(false);
        }
    }, [eventID, userID, API_LINK, setEvents]);

    return (
        <tr>
            <td style={{ padding: '10px', color: 'white' }}>
                <InvisibleInput value={firstname} onInput={handleChangeData}
                    onBlur={updateUser} name={'firstname'} type={"text"} />
            </td>
            <td style={{ padding: '10px', color: 'white' }}>
                <InvisibleInput value={lastname} onInput={handleChangeData}
                    onBlur={updateUser} name={'lastname'} type={"text"} />
            </td>
            <td style={{ padding: '10px', color: 'white' }}>
                <InvisibleInput value={email} onInput={handleChangeData}
                    onBlur={updateUser} name={'email'} type={"email"} />
            </td>
            <td style={{ padding: '10px', color: 'white' }}>
                <InvisibleInput value={age} onInput={handleChangeData}
                    onBlur={updateUser} name={'age'} type={"number"} />
            </td>
            <td style={{ padding: '10px', color: 'white' }}>
                <div style={{ width: '100%' }}>
                    <DropdownButton
                        id="custom-dropdown"
                        title={gender}
                        onSelect={handleSelectGender}
                        variant="info"
                    >
                        <Dropdown.Item eventKey="Male">Male</Dropdown.Item>
                        <Dropdown.Item eventKey="Female">Female</Dropdown.Item>
                    </DropdownButton>
                </div>
            </td>
            <td style={{ padding: '10px', color: 'white' }}>
                <InvisibleInput
                    value={formatTagNumber(tagNumber)}
                    onInput={handleChangeData}
                    onBlur={updateUser}
                    name={'id_number'}
                    type={"text"}
                />
            </td>
            <td style={{ padding: '10px', color: 'white' }}>
                <button className="green-glow-button" onClick={() => { handleShowCompetitorInfo() }}>
                    Weights
                </button>
            </td>
            <td style={{ textAlign: 'center', color: 'white' }}>
                {deletingCompetitor || updatingUser ? (
                    <Spinner animation="border" size="sm" />
                ) : (
                    <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faTrash} onClick={removeCompetitor} />
                )}
            </td>
        </tr>
    );
};

export default MapCompetitor;
