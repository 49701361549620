import React, { useContext } from 'react';
import GetEventInfo from './GetEventInfo';
import { Context } from '../../../../lib/Context';

const MapEvent = (props) => {
    const { eventType, getCompetitors } = props
    const { events } = useContext(Context);

    return (
        events?.length > 0 &&
        events.map((event, index) => (
            event.eventType === 'Hybrid' && eventType === 'Seasons' ?
                <GetEventInfo key={'map-event-' + event._id} event={event} index={index} eventType={eventType}
                    getCompetitors={getCompetitors} />
                :
                event.eventType === 'Live' && eventType === 'Live Events' ?
                    <GetEventInfo key={'map-event-' + event._id} event={event} index={index} eventType={eventType}
                        getCompetitors={getCompetitors} />
                    :
                    event.eventType === 'Virtual' && eventType === 'Virtual Events' ?
                        <GetEventInfo key={'map-event-' + event._id} event={event} index={index} eventType={eventType}
                            getCompetitors={getCompetitors} />
                        :
                        eventType === 'All Events' &&
                        <GetEventInfo key={'map-event-' + event._id} event={event} index={index} eventType={eventType}
                            getCompetitors={getCompetitors} />
        ))
    )
}

export default MapEvent