import { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
import '../../lib/css/AddEventModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRetweet } from '@fortawesome/free-solid-svg-icons';
import AddSingleCompetitor from './ModalElements/AddSingleCompetitor';
import AddMultipleCompetitors from './ModalElements/AddMultipleCompetitors';
import Papa from 'papaparse';
import '../../lib/css/DarkModal.css';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';


const AddCompetitorsModal = () => {
    const { API_LINK, showAddCompetitorModal, setShowAddCompetitorModal, setAddCompetitorEventID, addCompetitorEventID,
        setEvents, setShowAlert, setAlertObject
    } = useContext(Context);
    const [addIndividual, setAddIndividual] = useState(false);
    const [competitors, setCompetitors] = useState([]);
    const [email, setEmail] = useState('');
    const [age, setAge] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [gender, setGender] = useState('Male');
    const [tagNumber, setTagNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [csv, setCsv] = useState(null);

    const checkDataIsValid = () => {
        if (addIndividual) {
            if (!(firstname.length > 0 && lastname.length > 0 && tagNumber.length > 0 &&
                (age !== 0 || age !== ''))) {
                setShowAlert(true);
                let alertMessage = {
                    variant: 'danger',
                    text: 'All of the data needs to be filled in before you can submit.'
                }
                setAlertObject(alertMessage);
            }
            return firstname.length > 0 && lastname.length > 0 && tagNumber.length > 0 &&
                (age !== 0 || age !== '')
        } else {
            if (!csv) {
                setShowAlert(true);
                let alertMessage = {
                    variant: 'danger',
                    text: 'Please add a CSV file to the input.'
                }
                setAlertObject(alertMessage);
            }
            return csv
        }

    }

    const addCompetitors = async () => {
        // Perform check to ensure all the data is present before continuing.
        if (checkDataIsValid()) {
            setIsLoading(true);
            const response = await fetch(API_LINK + '/add-competitors', {
                method: 'POST',
                body: JSON.stringify({ competitors, eventID: addCompetitorEventID }),
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            })
            if (response.ok) {
                response.json().then(async data => {
                    if (data.error) {
                        console.log('There was an error: ' + data.error);
                    } else {
                        // setCompetitors(data.competitors);

                        if (data.adjudicatorFound) {
                            setShowAlert(true);
                            let alertMessage = {
                                variant: 'danger',
                                text: 'You can\'t add adjudicators as competitors to an event.'
                            }
                            setAlertObject(alertMessage);
                        } else {
                            setEmail('');
                            setAge(0);
                            handleClose();
                        }
                        setEvents(data.events);
                        setIsLoading(false);
                    }
                });
            }
        }
    }

    const handleSelectGender = (eventGender) => {
        setGender(eventGender);
    }

    const handleAddData = (event) => {
        let type = event.target.name;
        let value = event.target.value;
        switch (type) {
            case 'email':
                setEmail(value);
                break;
            case 'age':
                setAge(value);
                break;
            case 'firstname':
                setFirstname(value);
                break;
            case 'lastname':
                setLastname(value);
                break;
            case 'tag_number':
                setTagNumber(value);
                break;
            default:
                break;
        }
        let singleCompetitor = [];
        singleCompetitor.push({ email, age, firstname, lastname, tag_number: tagNumber, gender })
        setCompetitors(singleCompetitor);
    }

    const addFile = (event) => {
        const file = event.target.files[0];
        setCsv(file);
        if (file) {
            Papa.parse(file, {
                header: true, // Assumes the CSV file has headers
                skipEmptyLines: true,
                complete: (result) => {
                    let csvData = [];
                    for (let i = 0; i < result.data.length; i++) {
                        let firstname = result.data[i].firstname || '';
                        let lastname = result.data[i].lastname || '';
                        let gender = result.data[i].gender || '';
                        let tag_number = result.data[i].tag_number || '';
                        let email = result.data[i].email;
                        let age = result.data[i].age || 0;
                        csvData.push({ firstname, lastname, gender, email, age, tag_number });
                    }
                    setCompetitors(csvData); // Set parsed data as JSON
                },
                error: (error) => {
                    console.error("Error parsing the CSV file:", error);
                }
            });
        }
    }

    const handleClose = () => {
        setShowAddCompetitorModal(false);
        setAddCompetitorEventID('');
        setCsv(null);
        setTimeout(() => {
            setAddIndividual(false);
        }, "200");
    };

    const toggleAddIndividual = () => {
        setAddIndividual(!addIndividual);
    }

    return (
        <Modal show={showAddCompetitorModal} onHide={handleClose} className="modal-dark">
            <Modal.Header closeButton>
                <Modal.Title>Add {addIndividual ? 'Competitor' : 'Competitors'} to Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {addIndividual ?
                    <AddSingleCompetitor
                        handleAddData={handleAddData}
                        age={age === 0 ? '' : age}
                        email={email}
                        firstname={firstname}
                        lastname={lastname}
                        gender={gender}
                        tagNumber={tagNumber}
                        handleSelectGender={handleSelectGender}
                    />
                    :
                    <AddMultipleCompetitors addFile={addFile} />
                }
                <Form.Text id="info-block" muted >
                    <span style={{ color: 'white', marginTop: '10px', fontStyle: 'italic' }}>
                        NOTE: If you try to add an adjudicator here you will get an error message
                        as you can't register an adjudicator as a competitor{addIndividual && '.'} {!addIndividual &&
                            ' but any non adjudicator competitors you try to add will be added despite the warning.'}
                    </span>

                </Form.Text>

            </Modal.Body>
            <Modal.Footer>
                <div style={{ width: '100%' }}>
                    <button className="white-glow-button float-left" style={{ float: 'left' }} onClick={toggleAddIndividual}>
                        <FontAwesomeIcon icon={faRetweet} /> Add {addIndividual ? 'Multiple' : 'Single'}
                    </button>
                    <button className="green-glow-button float-right" onClick={addCompetitors} style={{ width: '200px' }}>
                        {!isLoading ? `Add ${addIndividual ? 'Competitor' : 'Competitors'}`
                            :
                            <Spinner animation="border" size="sm" />
                        }
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default AddCompetitorsModal;