import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import InvisibleInput from '../../Pages/PageElements/InvisibleInput';
import ExerciseReps from '../../Links/ExerciseReps';
import ExerciseTimes from '../../Links/ExerciseTimes';
import ExerciseDistance from '../../Links/ExerciseDistance';

const MapExercise = (props) => {
    const {
        index, exercise, handleChangeExerciseName, holderFunction, getTitle, handleSwitchType, handleChangeExerciseReps,
        handleChangeDistance, handleChangeExerciseTime, removeExercise
    } = props;

    return (
        <Row style={{ marginBottom: '10px' }}>
            <Col >
                {exercise.type === 'Time' ?
                    <InvisibleInput value={exercise.name} onInput={handleChangeExerciseName}
                        onBlur={holderFunction} name={index} type={"text"} disabled={true} />
                    :
                    <InvisibleInput value={exercise.name} onInput={handleChangeExerciseName}
                        onBlur={holderFunction} name={index} type={"text"} />
                }
            </Col>
            <Col xs={2}>
                <DropdownButton
                    id="custom-dropdown"
                    title={getTitle(exercise.type)}
                    onSelect={handleSwitchType}
                    variant="info"
                >
                    <Dropdown.Item eventKey={"CV-" + index}>Cardio</Dropdown.Item>
                    <Dropdown.Item eventKey={"Weights-" + index}>Lifts</Dropdown.Item>
                    <Dropdown.Item eventKey={"Time-" + index}>Time</Dropdown.Item>
                </DropdownButton>
            </Col>
            {exercise.type === 'Weights' ?
                <>
                    <Col xs={2} />
                    <Col xs={2}>
                        <ExerciseReps exercise={exercise} index={index} handleChangeExerciseReps={handleChangeExerciseReps} />
                    </Col>
                </>

                : exercise.type === 'CV' ?
                    <>
                        <Col xs={2}>
                            <ExerciseDistance exercise={exercise} index={index} handleChangeExerciseReps={handleChangeDistance} />
                        </Col>
                        <Col xs={2}>
                            <ExerciseTimes exercise={exercise} index={index} handleChangeExerciseReps={handleChangeExerciseTime} />
                        </Col>
                    </>
                    :
                    <>
                        <Col xs={4} />
                    </>
            }
            <Col xs={1} style={{ textAlign: 'center' }}>
                <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faTrash} onClick={() => {
                    removeExercise(index);
                }} />
            </Col>
        </Row>
    )
}

export default MapExercise