import React, { useEffect, useState } from 'react'
import MapEventGenders from './MapEventGenders';

const MapEventAgeGroup = (props) => {
    const { genders, groupIndex, ageGroups, event, adjudicators, filterAgeGroup, filterGenderGroup,
        isLoading, setIsLoading, getCompetitors
    } = props;

    const [low_age, setLow_age] = useState(0);
    const [high_age, setHigh_age] = useState(0);

    useEffect(() => {
        setLow_age(ageGroups[groupIndex]?.low_age);
        setHigh_age(ageGroups[groupIndex]?.high_age);
    }, [ageGroups, groupIndex])

    return (
        <div>
            {genders?.map((ageGroups, index) => {
                let gender = index === 0 ? 'Female' : 'Male';
                if (low_age >= filterAgeGroup.low_age && high_age <= filterAgeGroup.high_age && gender === filterGenderGroup) {
                    return <MapEventGenders key={'map-genders-' + low_age + '-' + high_age + index}
                        gender={gender}
                        competitors={ageGroups}
                        low_age={low_age}
                        high_age={high_age}
                        event={event}
                        adjudicators={adjudicators}
                        groupIndex={groupIndex}
                        index={index}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        getCompetitors={getCompetitors}
                    />
                } else {
                    return ''
                }
            })}
        </div>

    )
}

export default MapEventAgeGroup