import React, { useContext, useEffect, useState } from 'react'
import EventCompetitor from '../EventCompetitor';
import '../../../../lib/css/LeagueTable.css';
import { motion as m } from 'framer-motion'
import { Context } from '../../../../lib/Context';
import '../../../../lib/css/MapEventGenders.css'

const MapEventGenders = (props) => {
  const { gender, competitors, low_age, high_age, adjudicators, isLoading, setIsLoading, event,
    getCompetitors
  } = props;
  const { usersID, checkIsAdjudicator, isLiveEvent } = useContext(Context)
  let exercises = [...event.exercises]
  const [colSpan, setColSpan] = useState(0);
  const [colWidth, setColWidth] = useState('');
  const [adjudicator, setAdjudicator] = useState({});

  useEffect(() => {
    let cols = 0;
    for (let i = 0; i < event.exercises.length; i++) {
      if (event.exercises[i].type === 'Weights') {
        cols += 2
      } else {
        cols++
      }
    }
    setColSpan(cols)
    setColWidth(58 / cols + '%');
    for (let i = 0; i < event.adjudicators.length; i++) {
      if (event.adjudicators[i].userID === usersID) {
        setAdjudicator(event.adjudicators[i])
      }
    }
  }, [event.exercises, usersID, event.adjudicators])

  return (
    <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1.25, ease: 'easeOut' }} className="table-div">
      <div className="table-container">
        <table style={{ width: '100%', marginBottom: '30px', minWidth: '1200px' }} className="responsive-table">
          <thead>
            <tr>
              <th colSpan={colSpan + 3} style={{ paddingBottom: '30px' }}>{gender} ({low_age}{high_age !== 100 ? ' to ' + high_age : '+'})</th>
            </tr>
            <tr>
              <th style={{ width: '15%' }}>Name</th>
              {isLiveEvent && <th style={{ width: '8%' }}>Adjudicator</th>}
              {event.exercises.map((exercise, index) => {
                let type = exercise.type;
                if (type === 'Weights') {
                  return (
                    <th key={'map-exercise-' + exercise.name + gender + low_age + index} style={{ width: colWidth }} colSpan={2} >
                      {exercise.name}<br />
                      <div className="exercise-type">{exercise.type} <br />{'(' + exercise.reps + ' reps)'}</div>
                    </th>
                  )
                } else if (type === 'CV') {
                  return <th key={'map-exercise-' + exercise.name + gender + low_age + index} style={{ width: colWidth }} >
                    {exercise.name}<br />
                    <div className="exercise-type">{isLiveEvent ? exercise.type : 'Best Time'} <br />{
                      isLiveEvent ? '(' + exercise.time + ' mins)' : '(' + exercise.distance + ' km)'
                    }</div>
                  </th>
                } else {
                  return isLiveEvent && <th key={'map-exercise-' + exercise.name + gender + low_age + index} style={{ width: '6%' }} >
                    Total {exercise.name}
                  </th>
                }
              })}
              <th style={{ width: '5%' }}>Total Score</th>
            </tr>
          </thead>
          <tbody>
            {competitors.map((competitor, index) => {
              if (isLiveEvent && checkIsAdjudicator(event._id)) {
                if (competitor.adjudicator.userID === usersID) {
                  return <EventCompetitor key={'map-out-competitor-list-' + competitor._id + '-' + index}
                    competitorObject={competitor}
                    index={index}
                    adjudicators={adjudicators}
                    exercises={exercises}
                    event={event}
                    low_age={low_age}
                    high_age={high_age}
                    gender={gender}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    getCompetitors={getCompetitors}
                    adjudicator={adjudicator}
                  />
                }
              } else {
                return <EventCompetitor key={'map-out-competitor-list-' + competitor._id + '-' + index}
                  competitorObject={competitor}
                  index={index}
                  adjudicators={adjudicators}
                  exercises={exercises}
                  event={event}
                  low_age={low_age}
                  high_age={high_age}
                  gender={gender}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  getCompetitors={getCompetitors}
                  adjudicator={adjudicator}
                  eventID={event._id}
                />
              }
              return ''
            })}
          </tbody>
        </table>
      </div>
    </m.div>
  )
}

export default MapEventGenders