import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
import CompulsoryProfileInfo from './ModalElements/CompulsoryProfileInfo';
import ValidateEmail from './ModalElements/ValidateEmail';
import SquarePayment from '../Pages/PageElements/PaymentElements/SquarePayment';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

const BuySeasonPassModal = () => {
    const { API_LINK, showBuyPassModal, setShowBuyPassModal, setShowBuyPassEventID, showBuyPassEventID, usersID,
        events, isLiveEvent, dob, setDob, gender, setGender, firstname, setFirstname, lastname, setLastname,
        phoneNumber, setPhoneNumber, deliveryAddress, deliveryAddressTwo, setDeliveryAddress, setDeliveryAddressTwo, city,
        postcode, setPostcode, infoValid, setInfoValid, getCompetitorsInfo, emailValidated, userInfoValid, setCity,
        setShowAlert, setAlertObject
    } = useContext(Context);

    // eslint-disable-next-line
    const [price, setPrice] = useState(0);
    const [seasonNumber, setSeasonNumber] = useState(0);
    const [eventName, setEventName] = useState('');
    const [eventType, setEventType] = useState('')
    // eslint-disable-next-line
    const [processingPayment, setProcessingPayment] = useState(false);
    const [infoSaved, setInfoSaved] = useState(false);

    // firstname, lastname, phoneNumber, deliveryAddress, deliveryAddressTwo, city, postcode, dob, gender
    const [thisFirstname, setThisFirstname] = useState(firstname);
    const [thisLastname, setThisLastname] = useState(lastname);
    const [thisPhoneNumber, setThisPhoneNumber] = useState(phoneNumber);
    const [thisDeliveryAddress, setThisDeliveryAddress] = useState(deliveryAddress);
    const [thisDeliveryAddressTwo, setThisDeliveryAddressTwo] = useState(deliveryAddressTwo);
    const [thisCity, setThisCity] = useState(city);
    const [thisPostcode, setThisPostcode] = useState(postcode);
    const [thisDob, setThisDob] = useState(dob);
    const [thisGender, setThisGender] = useState(gender);

    useEffect(() => {
        getCompetitorsInfo();
    }, [getCompetitorsInfo])

    // Effect to sync thisDob with dob from context
    useEffect(() => {
        setThisFirstname(firstname);
        setThisLastname(lastname);
        setThisPhoneNumber(phoneNumber);
        setThisDeliveryAddress(deliveryAddress);
        setThisDeliveryAddressTwo(deliveryAddressTwo);
        setThisCity(city);
        setThisPostcode(postcode);
        setThisGender(gender);
        setThisDob(dob); // Update thisDob whenever dob from context changes
        for (let i = 0; i < events?.length; i++) {
            if (events[i]._id === showBuyPassEventID) {
                if (isLiveEvent) {
                    setPrice(events[i].liveFee);
                    setEventName(`${events[i].event_name}`);
                } else {
                    setPrice(events[i].virtualFee);
                    setEventName(`${events[i].event_name}`);
                }
                setEventType(events[i].eventType);
                setSeasonNumber(events[i].season_number);
            }
        }
    }, [
        dob, events, showBuyPassEventID, isLiveEvent, city, deliveryAddress, deliveryAddressTwo,
        firstname, gender, lastname, phoneNumber, postcode
    ]);

    const updateCompulsoryInfo = async () => {
        // Run method to show warning message if info is not valid
        let userValid = userInfoValid({
            firstname: thisFirstname, lastname: thisLastname, phoneNumber: thisPhoneNumber, deliveryAddress: thisDeliveryAddress,
            deliveryAddressTwo: thisDeliveryAddressTwo, city: thisCity, postcode: thisPostcode, dob: thisDob, gender: thisGender
        });
        if (userValid) {
            const response = await fetch(API_LINK + '/update-compulsory-info', {
                method: 'POST',
                body: JSON.stringify({
                    firstname: thisFirstname, lastname: thisLastname, phoneNumber: thisPhoneNumber,
                    deliveryAddress: thisDeliveryAddress, deliveryAddressTwo: thisDeliveryAddressTwo,
                    city: thisCity, postcode: thisPostcode, dob: thisDob, gender: thisGender, userID: usersID
                }),
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            })
            if (response.ok) {
                response.json().then(async data => {
                    if (data.error) {
                        console.log('There was an error: ' + data.error);
                    } else {
                        let user = data.user;
                        setFirstname(user?.firstname);
                        setLastname(user?.lastname);
                        setPhoneNumber(user?.phone_number);
                        setDeliveryAddress(user?.delivery_address);
                        setDeliveryAddressTwo(user?.delivery_address_two);
                        setPostcode(user?.postcode);
                        setCity(user?.city);
                        setDob(user?.dob);
                        setGender(user?.gender);
                        let userValid = userInfoValid({
                            firstname: user?.firstname,
                            lastname: user?.lastname,
                            phoneNumber: user?.phone_number,
                            deliveryAddress: user?.delivery_address,
                            deliveryAddressTwo: user?.delivery_address_two,
                            city: user?.city,
                            postcode: user?.postcode,
                            dob: user?.dob,
                            gender: user?.gender
                        });
                        setInfoValid(userValid);
                        setInfoSaved(true);
                    }
                });
            }
        } else {
            setShowAlert(true);
            let alertMessage = {
                variant: 'danger',
                text: `Please fill in the required fields!`
            }
            setAlertObject(alertMessage);
        }
    }

    const handleChangeValue = (event) => {
        let type = event.target.name;
        let value = event.target.value;
        switch (type) {
            case 'firstname':
                setThisFirstname(value);
                break;
            case 'lastname':
                setThisLastname(value);
                break;
            case 'phone-number':
                setThisPhoneNumber(value);
                break;
            case 'delivery-address':
                setThisDeliveryAddress(value);
                break;
            case 'delivery-address-two':
                setThisDeliveryAddressTwo(value);
                break;
            case 'city':
                setThisCity(value);
                break;
            case 'postcode':
                setThisPostcode(value);
                break;
            default:
                break;
        }
        setInfoValid(userInfoValid({
            firstname: thisFirstname, lastname: thisLastname, phoneNumber: thisPhoneNumber, deliveryAddress: thisDeliveryAddress,
            deliveryAddressTwo: thisDeliveryAddressTwo, city: thisCity, postcode: thisPostcode, dob: thisDob, gender: thisGender
        }));
    }

    const handleSelectGender = async (eventGender) => {
        setThisGender(eventGender);
        // TODO: Actually update the gender on the backend
        await fetch(API_LINK + '/update-users-gender', {
            method: 'POST',
            body: JSON.stringify({
                userID: usersID, gender: eventGender
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
    };

    const handleClose = () => {
        setShowBuyPassModal(false);
        setTimeout(() => {
            setShowBuyPassEventID('');
            getCompetitorsInfo();
            setInfoSaved(false);
            setProcessingPayment(false);
        }, 2000);

    }

    return (
        <Modal show={showBuyPassModal} onHide={handleClose} className="modal-dark">
            {!emailValidated &&
                <Modal.Header closeButton>
                    <Modal.Title>Validation Required</Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>
                {emailValidated ?
                    infoValid && infoSaved ? (
                        processingPayment ?
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                            :
                            <>
                                <h1 className="glow-text" style={{
                                    textAlign: 'center', marginBottom: '10px', fontFamily: 'Neon',
                                    color: '#26ffe2', fontSize: '4em', lineHeight: '0.7em'
                                }}>
                                    {eventType === 'Hybrid' && `Season #${seasonNumber}`}
                                    {(eventType === 'Live' || eventType === 'Virtual') && eventName}
                                    <br />£{price}
                                </h1>
                                <h3 className="glow-text" style={{
                                    textAlign: 'center', marginBottom: '30px', fontFamily: 'Neon',
                                    color: '#26ffe2', lineHeight: '0.7em'
                                }}>
                                    {isLiveEvent ? 'LIVE EVENT' : 'VIRTUAL EVENT'}
                                </h3>
                                <SquarePayment price={price} seasonNumber={seasonNumber} handleClose={handleClose}
                                    setProcessingPayment={setProcessingPayment} eventName={eventName} eventType={eventType} />
                            </>
                    )
                        :
                        (
                            <>
                                <p>Before you can purchase this pass we need to make sure we've got all the info we need.</p>
                                <CompulsoryProfileInfo
                                    dob={thisDob}
                                    setDob={setThisDob}
                                    gender={thisGender}
                                    firstname={thisFirstname}
                                    lastname={thisLastname}
                                    phoneNumber={thisPhoneNumber}
                                    deliveryAddress={thisDeliveryAddress}
                                    postcode={thisPostcode}
                                    handleChangeValue={handleChangeValue}
                                    handleSelectGender={handleSelectGender}
                                    deliveryAddressTwo={thisDeliveryAddressTwo}
                                    city={thisCity}
                                />
                                {userInfoValid({
                                    firstname: thisFirstname, lastname: thisLastname, phoneNumber: thisPhoneNumber, deliveryAddress: thisDeliveryAddress,
                                    deliveryAddressTwo: thisDeliveryAddressTwo, city: thisCity, postcode: thisPostcode, dob: thisDob, gender: thisGender
                                }) ?
                                    <button className="green-glow-button-large" style={{ marginTop: '30px' }} id="submit"
                                        onClick={updateCompulsoryInfo}>
                                        <span id="button-text">
                                            CONTINUE
                                        </span>
                                    </button>
                                    :
                                    <Alert variant={'danger'} style={{ textAlign: 'center', marginTop: '60px' }}>
                                        Please enter in the required information
                                    </Alert>
                                }

                            </>
                        )
                    :
                    <ValidateEmail />
                }
            </Modal.Body>
        </Modal>
    )
}

export default BuySeasonPassModal