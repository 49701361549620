import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
import CollectWeightsInput from '../Pages/PageElements/CollectWeightsInput';
import Form from 'react-bootstrap/Form';

const CollectUserWeightsModal = () => {
    const { API_LINK, showCollectWeightsModal, setShowCollectWeightsModal, setCollectWeightsEventID, collectWeightsEventID,
        events, firstname, lastname, usersID, setEvents
    } = useContext(Context);
    const [startTime, setStartTime] = useState('');
    const [exercises, setExercises] = useState([]);
    const [exerciseWeights, setExerciseWeights] = useState(new Map());
    const [isLive, setIsLive] = useState(false);

    useEffect(() => {
        for (let i = 0; i < events?.length; i++) {
            if (events[i]._id === collectWeightsEventID) {
                setIsLive(events[i].isLive)
                let eventType = events[i].eventType;
                let date = ''
                if (eventType === 'Live' || eventType === 'Hybrid') {
                    date = events[i].start_time_live.split('T')[0]
                }
                let year = date.split('-')[0]
                let month = date.split('-')[1]
                let day = date.split('-')[2]
                setStartTime(day + '-' + month + '-' + year);
                let exercises = []
                for (let j = 0; j < events[i].exercises.length; j++) {
                    if (events[i].exercises[j].type === 'Weights') {
                        exercises.push(events[i].exercises[j].name)
                    }
                }
                setExercises(exercises);
                for (let j = 0; j < events[i].competitors.length; j++) {
                    if (events[i].competitors[j].userID === usersID) {
                        // Set values here
                        let exercise_weights = new Map(Object.entries(events[i].competitors[j].exercise_weights));
                        setExerciseWeights(exercise_weights);
                    }
                }
            }
        }
    }, [events, collectWeightsEventID, usersID])

    const handleClose = () => {
        setCollectWeightsEventID('')
        setShowCollectWeightsModal(false);
    }

    const handleUpdateWeight = (event) => {
        const type = event.target.name;
        const value = event.target.value;
        let exercise_weights = new Map([...exerciseWeights]);
        exercise_weights.set(type, value);
        setExerciseWeights(exercise_weights);
    }

    const submitUserWeights = async () => {
        const exercise_weights = Array.from(exerciseWeights.entries());
        const response = await fetch(API_LINK + '/submit-users-weights', {
            method: 'POST',
            body: JSON.stringify({ eventID: collectWeightsEventID, exercise_weights }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                setEvents(data.events);
                handleClose();
            });
        }
    }

    return (
        <Modal show={showCollectWeightsModal && !isLive} onHide={handleClose} className="modal-dark" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Competition Weights for {firstname} {lastname}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Hi {firstname}, you are currently entered in in the upcoming event starting on {startTime}.
                We need to collect your desired weights for the day for each exercise.
                The exercises we have that we need information for are:
                <Form style={{ marginTop: '30px', marginBottom: '30px' }}>
                    {exercises.map((exercise, index) => {
                        return (
                            <CollectWeightsInput
                                key={'map-exercises-' + index + '-' + usersID}
                                exercise={exercise}
                                handleUpdateWeight={handleUpdateWeight}
                                exerciseWeights={exerciseWeights}
                            />
                        )
                    })}
                </Form>
                Note: Please enter in a number into each of the above inputs. This will be the weight that you will be
                lifting in the live event. You can make changes to your weights (if you need to) when you are checking
                in on the day.
            </Modal.Body>
            <Modal.Footer>
                <button className="grey-glow-button" onClick={handleClose}>
                    Close
                </button>
                <button className="green-glow-button" onClick={submitUserWeights}>
                    Save Changes
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default CollectUserWeightsModal