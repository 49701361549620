import React from 'react';
import Form from 'react-bootstrap/Form';

const ExerciseReps = (props) => {
    let { exercise, index, handleChangeExerciseReps } = props;

    return (
        <>
            <Form.Control
                value={exercise.reps === 0 ? '' : exercise.reps}
                name={exercise.name.toLowerCase().replace(/\s+/g, '_').replace(/[^\w_]+/g, '') + '~' + index}
                type="text"
                id={"exercise-reps-" + index}
                aria-describedby="passwordHelpBlock"
                placeholder={'Reps'}
                onInput={handleChangeExerciseReps}
            />
        </>
    )
}

export default ExerciseReps