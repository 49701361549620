import React from 'react';
import { Dropdown } from 'react-bootstrap';


const FilterAgeGroupsDropdown = (props) => {
    const { ageGroups, filterAgeGroup, handleSetFilterAgeGroups } = props;
    return (
        <Dropdown style={{ float: 'right' }}>
            <Dropdown.Toggle variant="light" id="custom-dropdown">
                {`${!filterAgeGroup.low_age ? filterAgeGroup[0] : filterAgeGroup.low_age
                    } to ${!filterAgeGroup.high_age ? filterAgeGroup[1] : filterAgeGroup.high_age
                    }`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {ageGroups?.map((ageGroup, index) => {
                    let age_group = ageGroup.low_age + ' to ' + ageGroup.high_age;
                    return <Dropdown.Item key={'map-' + age_group.low_age + index} onClick={() => {
                        handleSetFilterAgeGroups(ageGroup);
                    }}>
                        {age_group}
                    </Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default FilterAgeGroupsDropdown