import React, { useContext, useEffect, useState } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendUp, faPersonRunning, faScaleUnbalanced, faTicket } from '@fortawesome/free-solid-svg-icons';
import { Context } from '../../../../lib/Context';

const VirtualElement = (props) => {
    const { event, handleViewEvent, handleShowMapCompetitorsModal, getCompetitors } = props;
    const {
        setShowBuyPassModal, setShowBuyPassEventID, showBuyPassEventID, events, usersID, type, email,
        setIsLiveEvent, setShowUpdateVirtualScoresModal, setUpdateVirtualScoresEventID, setIsAdjudicator
    } = useContext(Context);
    const [userInEvent, setUserInEvent] = useState(false); // Is this user already paid up and in this event?
    let eventID = event._id;

    useEffect(() => {
        getCompetitors(eventID);
        for (let i = 0; i < events.length; i++) {
            if (events[i]._id === event._id) {
                for (let j = 0; j < events[i].virtual_competitors.length; j++) {
                    if (events[i].virtual_competitors[j].userID === usersID) {
                        setUserInEvent(true);

                    }
                }
            }
        }
    }, [events, showBuyPassEventID, usersID, event._id, getCompetitors, eventID])

    const handleShowBuyPassModal = () => {
        setShowBuyPassModal(true);
        setShowBuyPassEventID(eventID);
        setIsLiveEvent(false); // Because we are in a virtual element
    }

    const handleViewVirtualEvent = () => {
        setIsAdjudicator(false);
        setIsLiveEvent(false);
        handleViewEvent(event._id);
    }

    const handleShowUpdateEventScoresModal = () => {
        setShowUpdateVirtualScoresModal(true);
        setUpdateVirtualScoresEventID(event._id);
    }

    return (
        <div>
            {/* {event.eventType === 'Hybrid' &&
                <p style={{ marginBottom: '0px' }}><strong>Virtual Event</strong></p>
            } */}
            <Row style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
                {event.virtual_competitors.length > 0 && type === 'admin' &&
                    <Col>
                        <button className="green-glow-button-large" style={{
                            width: '100%', marginLeft: 'auto',
                            marginRight: 'auto', marginTop: '10px'
                        }}
                            variant="info" onClick={() => { handleShowMapCompetitorsModal({ eventID, type: "Virtual" }) }}>
                            <FontAwesomeIcon icon={faPersonRunning} style={{ marginRight: '5px' }} /> Edit Competitors
                        </button>
                    </Col>
                }
                {userInEvent ?
                    <Col xs={12} sm={6}>
                        <button className="green-glow-button-large" style={{
                            width: '100%', marginLeft: 'auto',
                            marginRight: 'auto', marginTop: '10px'
                        }}
                            variant="info" onClick={handleShowUpdateEventScoresModal}>
                            <FontAwesomeIcon icon={faScaleUnbalanced} style={{ marginRight: '20px' }} /> Update Scores
                        </button>
                    </Col>
                    : type !== 'admin' && email &&
                    <Col xs={12} sm={6}>
                        <button className="green-glow-button-large" style={{
                            width: '100%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px'
                        }}
                            variant="info" onClick={() => { handleShowBuyPassModal({ type: 'Virtual' }) }}>
                            <FontAwesomeIcon icon={faTicket} style={{ marginRight: '20px' }} /> Buy {event.eventType === 'Hybrid' ? 'Season' : 'Event'} Pass
                        </button>
                    </Col>
                }
                {type === 'admin' && email ?
                    <Col xs={12}>
                        <button className="green-glow-button-large" style={{
                            width: '100%', marginLeft: 'auto',
                            marginRight: 'auto', marginTop: '10px'
                        }}
                            variant="info" onClick={handleViewVirtualEvent}>
                            <FontAwesomeIcon icon={faArrowTrendUp} style={{ marginRight: '5px' }} /> View Leaderboard
                        </button>
                    </Col>
                    : email ?
                        <Col xs={12} sm={6}>
                            <button className="green-glow-button-large" style={{
                                width: '100%', marginLeft: 'auto',
                                marginRight: 'auto', marginTop: '10px'
                            }}
                                variant="info" onClick={handleViewVirtualEvent}>
                                <FontAwesomeIcon icon={faArrowTrendUp} style={{ marginRight: '5px' }} /> View Leaderboard
                            </button>
                        </Col>
                        :
                        <Col xs={12}>
                            <button className="green-glow-button-large" style={{
                                width: '100%', marginLeft: 'auto',
                                marginRight: 'auto', marginTop: '10px'
                            }}
                                variant="info" onClick={handleViewVirtualEvent}>
                                <FontAwesomeIcon icon={faArrowTrendUp} style={{ marginRight: '5px' }} /> View Leaderboard
                            </button>
                        </Col>
                }
            </Row>
        </div>
    )
}

export default VirtualElement