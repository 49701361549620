import React, { useContext } from 'react';
import { Context } from '../../../lib/Context';

const InvisibleInput = (props) => {
    const { value, onInput, onBlur, name, type, weight, disabled, onFocus } = props;
    const { loseFocus } = useContext(Context);

    return (
        <input
            type={type}
            value={value}
            onInput={onInput}
            onBlur={onBlur}
            onKeyDown={loseFocus}
            name={name}
            onFocus={onFocus || (() => { })} // Default to a no-op function if onFocus is null
            disabled={disabled}
            style={{
                border: 'none',
                background: 'transparent',
                fontFamily: 'inherit',
                fontSize: 'inherit',
                color: 'inherit',
                lineHeight: 'inherit',
                padding: 0,
                margin: 0,
                outline: 'none',
                width: '100%',
                fontWeight: weight
            }}
        />
    );
};

export default InvisibleInput;
