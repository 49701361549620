import React, { useContext, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import '../../../lib/css/AlertBar.css'; // Assuming you add CSS here
import { Context } from '../../../lib/Context';
import { motion as m } from 'framer-motion';

const AlertBar = () => {
    const { showAlert, setShowAlert, alertObject, setAlertObject } = useContext(Context);
    const variant = alertObject.variant;
    const text = alertObject.text;

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAlert(false);
            setAlertObject({});
        }, 2500);

        return () => clearTimeout(timer);
    }, [setShowAlert, setAlertObject]);

    return (
        showAlert && (
            <m.div
                initial={{ opacity: 0 }}  // Start faded out
                animate={{ opacity: 1 }}  // Fade in
                transition={{ duration: 0.75, ease: 'easeOut' }}
                className="custom-alert"
            >
                <Alert variant={variant}>
                    {text}
                </Alert>
            </m.div >
        )
    );
};

export default AlertBar