import React, { useContext } from 'react'
import AuthModal from './AuthModal'
import AddEventModal from './AddEventModal';
import AddCompetitorsModal from './AddCompetitorsModal';
import EditCompetitorsModal from './EditCompetitorsModal';
import EditCompetitorWeightsModal from './EditCompetitorWeightsModal';
import AddAdjudicatorModal from './AddAdjudicatorModal';
import EditAdjudicatorsModal from './EditAdjudicatorsModal';
import CollectUserWeightsModal from './CollectUserWeightsModal';
import EditProfileModal from './EditProfileModal'
import AlertBar from '../Pages/PageElements/AlertBar';
import { Context } from '../../lib/Context';
import AddCompetitorScoresModal from './AddCompetitorScoresModal';
import BuySeasonPassModal from './BuySeasonPassModal';
import UpdateVirtualEventScoresModal from './UpdateVirtualEventScoresModal';

// This file is where all of the modals that are needed to be contained in the app are kept.
const Modals = (props) => {
    const { showAlert } = useContext(Context)
    const { showAuth, setShowAuth } = props;
    return (
        <>
            <AuthModal showAuth={showAuth} setShowAuth={setShowAuth} />
            <AddEventModal />
            <AddCompetitorsModal />
            <EditCompetitorsModal />
            <EditCompetitorWeightsModal />
            <AddAdjudicatorModal />
            <EditAdjudicatorsModal />
            <CollectUserWeightsModal />
            {showAlert && <AlertBar />}
            <AddCompetitorScoresModal />
            <EditProfileModal />
            <BuySeasonPassModal />
            <UpdateVirtualEventScoresModal />
        </>
    )
}

export default Modals