import React from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { motion as m } from 'framer-motion';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import "../../../lib/css/DatePicker.css";

const EventInfo = (props) => {
    const {
        address, startDateLive, endDateLive, handleInput, setStartDateLive, setEndDateLive, postcode, seasonNumber,
        startDateVirtual, setStartDateVirtual, endDateVirtual, setEndDateVirtual, eventType, setEventType,
        virtualFee, liveFee, lockInEvent, eventName
    } = props;

    const handleSwitchType = (event) => {
        let value = event.split('-')[0];
        setEventType(value);
    }

    return (
        <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: 'easeOut' }}>
            <Row>
                {eventType === 'Hybrid' ?
                    <>
                        <Col xs={4} style={{ paddingTop: '20px' }}>
                            Season No.
                        </Col>
                        <Col xs={3}>
                            <Form.Control
                                type="number"
                                name='season_number'
                                placeholder='Season Number'
                                onInput={handleInput}
                                value={seasonNumber}
                                style={{ marginTop: '15px' }}
                            />
                        </Col>
                    </>
                    :
                    <> <Col xs={7}>
                        <Form.Control
                            type="text"
                            name='event_name'
                            placeholder='Event Name'
                            onInput={handleInput}
                            value={eventName}
                            style={{ marginTop: '15px' }}
                        />
                    </Col></>
                }

                <Col xs={5} style={{ paddingTop: '15px' }}>
                    <DropdownButton
                        id="custom-dropdown"
                        title={eventType === 'Hybrid' ? 'Season' : eventType}
                        onSelect={handleSwitchType}
                        variant="info"
                        disabled={lockInEvent}
                    >
                        <Dropdown.Item eventKey={"Hybrid"}>Season</Dropdown.Item>
                        <Dropdown.Item eventKey={"Live"}>Live</Dropdown.Item>
                        <Dropdown.Item eventKey={"Virtual"}>Virtual</Dropdown.Item>
                    </DropdownButton>
                </Col>
            </Row>
            {(eventType === 'Hybrid' || eventType === 'Live') &&
                <>
                    <p style={{ marginBottom: '-10px', marginTop: '30px' }}>
                        Please set an address for the <span style={{ color: '#26ffe2' }}>LIVE</span> event
                    </p>
                    <Form.Control
                        type="text"
                        name='address'
                        placeholder='Address'
                        onInput={handleInput}
                        value={address}
                        style={{ marginTop: '15px' }}
                    />
                    <Form.Control
                        type="text"
                        name='postcode'
                        placeholder='Postcode'
                        onInput={handleInput}
                        value={postcode}
                        style={{ marginTop: '15px' }}
                    />
                </>
            }
            {(eventType === 'Hybrid' || eventType === 'Virtual') &&
                <>
                    <p style={{ marginBottom: '-10px', marginTop: '30px' }}>
                        Please set dates for the <span style={{ color: '#26ffe2' }}>VIRTUAL</span> event
                    </p>
                    <Row>
                        <Col>
                            <DatePicker
                                selected={new Date(startDateVirtual)}
                                onChange={(date) => setStartDateVirtual(date)}
                                className="form-control"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15} // Time intervals in minutes
                                id="start-datePicker"
                            />
                        </Col>
                        <Col>
                            <DatePicker
                                selected={new Date(endDateVirtual)}
                                onChange={(date) => setEndDateVirtual(date)}
                                className="form-control"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15} // Time intervals in minutes
                                id="end-datePicker"
                            />
                        </Col>
                    </Row>
                </>
            }


            {(eventType === 'Hybrid' || eventType === 'Live') &&
                <>
                    <p style={{ marginBottom: '-10px', marginTop: '30px' }}>
                        Please set dates for the <span style={{ color: '#26ffe2' }}>LIVE</span> event
                    </p>
                    <Row>
                        <Col>
                            <DatePicker
                                selected={new Date(startDateLive)}
                                onChange={(date) => setStartDateLive(date)}
                                className="form-control"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15} // Time intervals in minutes
                                id="start-datePicker"
                            />
                        </Col>
                        <Col>
                            <DatePicker
                                selected={new Date(endDateLive)}
                                onChange={(date) => setEndDateLive(date)}
                                className="form-control"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15} // Time intervals in minutes
                                id="end-datePicker"
                            />
                        </Col>
                    </Row>
                </>
            }
            {(eventType === 'Virtual' || eventType === 'Hybrid') &&
                <Row style={{ marginTop: '30px' }}>
                    <Col>
                        <div style={{ paddingTop: '21px' }}>
                            Set fees for <span style={{ color: '#26ffe2' }}>VIRTUAL</span> event
                        </div>
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            name='virtual-fee'
                            placeholder='Price'
                            onInput={handleInput}
                            value={virtualFee === 0 ? '' : virtualFee}
                            style={{ marginTop: '15px' }}
                        />
                    </Col>
                </Row>
            }
            {(eventType === 'Live' || eventType === 'Hybrid') &&
                <Row style={{ marginTop: eventType === 'Live' ? '30px' : '0px' }}>
                    <Col>
                        <div style={{ paddingTop: '21px' }}>
                            Set fees for <span style={{ color: '#26ffe2' }}>LIVE</span> event
                        </div>
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            name='live-fee'
                            placeholder='Price'
                            onInput={handleInput}
                            value={liveFee === 0 ? '' : liveFee}
                            style={{ marginTop: '15px' }}
                        />
                    </Col>
                </Row>
            }

        </m.div >
    )
}

export default EventInfo