import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import InvisibleInput from '../InvisibleInput';
import { Context } from '../../../../lib/Context';

const MapAdjudicatorCompetitorScores = (props) => {
    const { eventExercises, event, competitor } = props
    const { API_LINK, events, addCompetitorScoreEventID, addCompetitorScoreCompetitorObject
    } = useContext(Context);


    const [exercises, setExercises] = useState(eventExercises);
    const [competitorWeights, setCompetitorWeights] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [editingField, setEditingField] = useState(null); // State to track the field being edited
    const [time, setTime] = useState('');
    const [eventScores, setEventScores] = useState({});
    const [eventID, setEventID] = useState('');

    useEffect(() => {
        setEventID(event._id);
        // Find the event and set the exercises
        for (let i = 0; i < events?.length; i++) {
            if (events[i]._id === eventID) {
                for (let j = 0; j < events[i].competitors?.length; j++) {
                    if (events[i].competitors[j].userID === competitor._id) {
                        // Set the competitor's scores in state
                        console.log('These are set')
                        setEventScores({ ...events[i].competitors[j].exercise_scores });
                        setCompetitorWeights({ ...events[i].competitors[j].exercise_weights });
                        setTime(events[i].competitors[j].exercise_scores['time']);
                    }
                }
                setExercises(events[i].exercises);
            }
        }
    }, [
        eventID, events, addCompetitorScoreCompetitorObject, addCompetitorScoreEventID, competitor._id,
        event._id
    ]);

    const handleFocus = (slug) => {
        setEditingField(slug); // Set the current editing field on focus
    };

    // Handle input changes for scores
    const handleChangeScore = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        if (value !== '') {
            value = parseInt(value, 10);
            if (isNaN(value)) value = 0; // Default to zero if parsing fails
        } else {
            value = ''; // Keep empty string if input is empty
        }

        let newScores = { ...eventScores };
        newScores[name] = value;
        setEventScores(newScores);
    };


    // Handle blur to update scores in the backend
    const handleBlur = async (event) => {
        let name = event.target.name;
        let value = event.target.value;

        if (value === '') value = 0;
        else {
            value = parseInt(value, 10);
            if (isNaN(value)) value = 0; // Default to zero if parsing fails
        }

        setIsLoading(true);
        setEditingField(null); // Clear the editing field on blur

        let newScores = { ...eventScores };
        newScores[name] = value;
        setEventScores(newScores);

        if (name === 'time') {
            value = time
        }

        // Send the update request to the backend
        const response = await fetch(API_LINK + '/adjudicator-update-score', {
            method: 'POST',
            body: JSON.stringify({ eventID, competitorID: competitor._id, slug: name, value }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        });

        if (response.ok) {
            response.json().then((data) => {
                // setEvents(data.events); // Update the events with the new data
                setIsLoading(false);
            });
        }
    };

    const handleChangeTime = (event) => {
        let value = event.target.value;
        // Remove leading zeros
        if (value?.length > 1 && value.startsWith('0')) {
            value = value.replace(/^0+/, '');
        }
        // Format the time as per the required mm:ss.sss format
        // const formattedTime = formatTime(value);
        setTime(value.replace(/[^0-9]/g, ''));
    }

    const formatTime = (value) => {
        // Convert value to a string in case it's a number or something else
        if (typeof value !== 'string') {
            value = String(value);
        }

        // Remove all non-numeric characters
        value = value.replace(/[^0-9]/g, '');

        // Insert colon after 2 digits for minutes
        if (value?.length > 2) {
            value = value.slice(0, 2) + ':' + value.slice(2);
        }

        // Insert period after seconds, if input length is greater than 5
        if (value?.length > 5) {
            value = value.slice(0, 5) + '.' + value.slice(5);
        }

        return value;
    };

    return (
        <div style={{ width: '100%', textAlign: 'center', paddingTop: '30px' }}>
            {exercises.map((exercise, index) => {
                const slug = exercise.name.toLowerCase().replace(/\s+/g, '_');
                return (
                    <Row key={'map-exercise-' + index} >
                        <Col xs={4} style={{
                            textAlign: 'right', marginBottom: '10px', paddingTop: '6px',
                            color: 'white'
                        }}>
                            <h5 style={{ marginBottom: '-5px' }}>{exercise.name}:</h5>
                            <span style={{ color: '#26ffe2' }}>
                                {exercise.type === 'Weights' && competitorWeights[slug] + 'kg'}
                                {exercise.type === 'CV' && exercise.time + ' mins'}
                            </span>
                        </Col>
                        <Col xs={4} style={{ fontSize: '1.5em', paddingTop: '10px' }}>
                            {isLoading ?
                                <Spinner animation="border" size="sm" />
                                :
                                slug !== 'time' ?
                                    <InvisibleInput
                                        value={eventScores[slug] || 0}
                                        onInput={handleChangeScore}
                                        onBlur={handleBlur}
                                        onFocus={() => handleFocus(slug)} // Set focus handler
                                        name={slug}
                                        type="tel"
                                        weight="bold"
                                        disabled={false}
                                        pattern="[0-9]*"
                                    />
                                    :
                                    <InvisibleInput
                                        value={formatTime(time) || 0}
                                        onInput={handleChangeTime}
                                        onBlur={handleBlur}
                                        onFocus={() => handleFocus(slug)} // Set focus handler
                                        name={slug}
                                        type="tel"
                                        weight="bold"
                                        disabled={false}
                                        pattern="[0-9]*"
                                    />
                            }

                        </Col>
                        <Col xs={4} style={{ textAlign: 'left', paddingTop: '7px' }}>
                            {/* Show icon only if this field is being edited */}
                            {editingField === slug && (
                                <button className="green-glow-button" >
                                    Save
                                </button>
                            )}
                        </Col>
                    </Row>
                );
            })}
        </div>
    )
}

export default MapAdjudicatorCompetitorScores