import React, { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import MapAdjudicatorCompetitorScores from './MapAdjudicatorCompetitorScores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


const MapAdjudicatorCompetitors = (props) => {
    const { competitors, event, handleClearEventInfo } = props;
    const [competitor, setCompetitor] = useState({});
    // eslint-disable-next-line
    const [scores, setScores] = useState(competitors[0].exercise_scores);
    const exercises = event.exercises

    const handleSelectCompetitor = async (competitorID) => {
        for (let i = 0; i < competitors.length; i++) {
            if (competitors[i]._id === competitorID) {
                setCompetitor(competitors[i]);
                setScores(competitors[i].exercise_scores)
            }
        }
    };

    return (
        <>
            <DropdownButton
                id="custom-dropdown"
                title={competitor?.firstname ? `${competitor.firstname} ${competitor.lastname} (${competitor.tag_number})` : 'Select Competitor'}
                onSelect={handleSelectCompetitor}
                variant="info"
                className="full-width-dropdown"
            >
                {competitors.map((competitor, index) => (
                    <Dropdown.Item eventKey={competitor._id} key={competitor._id + '-' + index} >
                        {competitor.firstname} {competitor.lastname} ({competitor.tag_number})
                    </Dropdown.Item>
                ))}
            </DropdownButton>
            {scores && exercises && competitor?.firstname &&
                <MapAdjudicatorCompetitorScores scores={scores} eventExercises={exercises}
                    event={event} competitor={competitor} handleClearEventInfo={handleClearEventInfo}
                />
            }
            <button className="green-glow-button"
                onClick={() => handleClearEventInfo('')} type="submit"
                style={{
                    float: 'left', marginBottom: '20px', width: '100%', padding: '7px',
                    marginTop: '30px'
                }}>
                <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faArrowLeft} /> Back to Events
            </button>
        </>
        // <div key={competitor._id + index} >{competitor.firstname} {competitor.lastname}</div>
    )
}

export default MapAdjudicatorCompetitors