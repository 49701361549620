import React from 'react'
import Form from 'react-bootstrap/Form';

const AddMultipleCompetitors = (props) => {
    const { addFile } = props

    return (
        <>
            <p>
                Please add multiple competitors here by uploading a <strong>CSV file</strong> with all
                the required email addresses, names, tag IDs and ages.
            </p>
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Control type="file" onChange={addFile} aria-describedby="info-block" />
            </Form.Group>
        </>
    )
}

export default AddMultipleCompetitors