import React, { useRef } from 'react';
import '../../../lib/css/VideoPlayer.css'; // Correct path for CSS

const VideoPlayer = () => {
    const videoRef = useRef(null);

    // Function to unmute the video and play it with sound
    const handlePlayWithSound = () => {
        if (videoRef.current) {
            videoRef.current.muted = false;  // Unmute the video
            videoRef.current.play();  // Ensure the video is playing
        }
    };

    return (
        <div className="video-wrapper">
            <video
                className="square-video"
                autoPlay
                loop
                muted  // Start muted to bypass autoplay restrictions
                playsInline
                ref={videoRef}
            >
                <source src="https://x-tronlive.com/content/uploads/2024/08/xtron-promo-2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {/* Button to unmute and play with sound */}
            <button onClick={handlePlayWithSound}>
                Play with Sound
            </button>
        </div>
    );
};

export default VideoPlayer;
