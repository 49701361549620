import React from 'react';
import Form from 'react-bootstrap/Form';

const CollectWeightsInput = (props) => {
    const { exercise, handleUpdateWeight, exerciseWeights } = props;
    const slug = exercise.toLowerCase().replace(/\s+/g, '_');
    return (
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>{exercise}</Form.Label>
            <Form.Control type="number"
                value={exerciseWeights.get(slug) || ''}
                placeholder={'Enter weight in kg'}
                onInput={handleUpdateWeight}
                name={slug} />
        </Form.Group>
    )
}

export default CollectWeightsInput