import React, { useContext, useEffect, useState } from 'react'
import { motion as m } from 'framer-motion';
import { Context } from '../../lib/Context'
import Modal from 'react-bootstrap/Modal';
import '../../lib/css/App.css';
import InvisibleInput from '../Pages/PageElements/InvisibleInput';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';

const EditCompetitorWeightsModal = () => {
    const { API_LINK, events, showEditCompetitorWeightsModal, setShowEditCompetitorWeightsModal, editWeightsUser,
        setEditUserWeightsUser, setCompetitors, competitors, editUserWeightsEventID, setEvents
    } = useContext(Context)
    const eventID = editUserWeightsEventID;

    // State for exercises and currently editing field
    const [exercises, setExercises] = useState([]);
    const [editingField, setEditingField] = useState(null); // Track the current field being edited

    useEffect(() => {
        if (editWeightsUser) {
            let exerciseList = [];
            const event = events?.find(e => e._id === eventID);  // Find the event only once
            if (event) {
                const competitor = event.competitors.find(c => c.userID === editWeightsUser._id);  // Find the user only once
                if (competitor) {
                    event.exercises.forEach(exercise => {
                        let slug = exercise.name.toLowerCase().replace(/ /g, '_');
                        exerciseList.push({
                            name: exercise.name,
                            type: exercise.type,
                            weight: competitor.exercise_weights[slug] || 0
                        });
                    });
                }
                setExercises(exerciseList);
            }
        }
    }, [editWeightsUser, eventID, events]);

    const handleClose = () => {
        setShowEditCompetitorWeightsModal(false);
        setEditUserWeightsUser({});
    }

    const updateUserWeights = async () => {
        const response = await fetch(API_LINK + '/update-user-weights', {
            method: 'POST',
            body: JSON.stringify({ exercises, userID: editWeightsUser._id, eventID }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                let competitor = data.competitor;
                let oldCompetitors = [...competitors]
                for (let i = 0; i < oldCompetitors.length; i++) {
                    if (oldCompetitors[i]._id === competitor._id) {
                        oldCompetitors[i] = competitor
                    }
                }
                setCompetitors(oldCompetitors);
                setEvents(data.events);
            });
        }
    }

    const handleChangeWeights = (event) => {
        let exerciseName = event.target.name;
        let value = event.target.value;
        // Remove leading zeros
        if (value.length > 1 && value.startsWith('0')) {
            value = value.replace(/^0+/, '');
        } else if (value === '') {
            value = 0;
        }

        let existingExercises = [...exercises];
        for (let i = 0; i < existingExercises.length; i++) {
            if (existingExercises[i].name === exerciseName) {
                existingExercises[i].weight = value;
            }
        }
        setExercises(existingExercises);
    }

    const handleFocus = (slug) => {
        setEditingField(slug); // Set the field being edited
    }

    const handleBlur = () => {
        setEditingField(null); // Clear the field when blurred
        updateUserWeights(); // Trigger weight update on blur
    }

    return (
        <Modal size="xs" show={showEditCompetitorWeightsModal}
            onHide={handleClose} className="modal-dark"
            style={{ backgroundImage: 'linear-gradient(#13066C, #070039)' }}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Editing Weights for {editWeightsUser && editWeightsUser.firstname} {editWeightsUser && editWeightsUser.lastname}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: 'easeOut' }}>
                    {exercises.length !== 0 && exercises.map((exercise, index) => (
                        exercise.type === "Weights" && (
                            <Row key={index}>
                                <Col xs={6} style={{ textAlign: 'right' }}>
                                    <h5 style={{ marginBottom: '30px' }}>{exercise.name}:</h5>
                                </Col>
                                <Col xs={3}>
                                    <InvisibleInput
                                        value={exercise.weight}
                                        onInput={handleChangeWeights}
                                        onBlur={handleBlur}
                                        onFocus={() => handleFocus(exercise.name)} // Track focus
                                        name={exercise.name}
                                        type={"number"}
                                    />
                                </Col>
                                <Col xs={3}>
                                    {/* Show the save button only if this field is being edited */}
                                    {editingField === exercise.name && (
                                        <button className="green-glow-button" style={{ width: '100%' }}>
                                            Save
                                        </button>
                                    )}
                                </Col>
                            </Row>
                        )
                    ))}
                    <p style={{ textAlign: 'center' }}><i>NOTE: Please enter in the weights for this user in KG.</i></p>
                </m.div>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ width: '100%' }}>
                    <button className="green-glow-button float-right" style={{ width: '100%' }} onClick={handleClose}>
                        Back
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default EditCompetitorWeightsModal;
