import React from 'react';
import Form from 'react-bootstrap/Form';

const ExerciseTimes = (props) => {
    let { exercise, index, handleChangeExerciseReps } = props;

    return (
        <Form.Control
            value={exercise.distance || ''}
            name={exercise.name.toLowerCase().replace(/\s+/g, '_').replace(/[^\w_]+/g, '') + '~' + index}
            type="text"
            id={"exercise-reps-" + index}
            aria-describedby="passwordHelpBlock"
            placeholder={'km'}
            onInput={handleChangeExerciseReps}
        />
    )
}

export default ExerciseTimes