import React from 'react'

const ModalHeader = (props) => {
    const { text, textAlign, fontSize, marginTop, color } = props;
    return (
        <div className="glow-text"
            style={{
                fontFamily: 'Neon',
                textAlign: textAlign || 'center',
                fontSize: fontSize || '1.3em',
                marginTop: marginTop || '10px',
                color: color || '#26ffe2'
            }}>
            {text}
        </div>
    )
}

export default ModalHeader